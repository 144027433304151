import React, { FunctionComponent, useState } from 'react';

import { Logo } from './Logo';
import PlayerIcon from "./user_icon.png";
import { Input } from "./Input";
import { Button } from "./Button";
import { ShareOptions } from './ShareOptions';
import { Player } from "./types";
import { Wrap } from "./Layout";
import _ from "lodash";

interface Props {
    playerName?: string;
    gameId?: string;
    otherPlayers: Player[];
    setPlayerName: (name: string) => void;
    createGame: (playerName: string) => void;
    startGame: (playerName: string) => void;
}

const ShareDetails = ({ gameId, newGame, startGame }: { gameId: string, newGame: boolean, startGame: () => void }) => {
    return <Wrap>
        <Logo />
        <h1 className="text-white text-5xl font-extrabold">Invite Players</h1>
        <p className="text-white text-2xl mt-8 mb-8 text-center">Share the game link to continue playing against others in the same video call, or continue playing solo</p>
        <ShareOptions gameId={gameId} />
        <Button text={newGame ? "Start Game" : "Continue"} disabled={false} onClick={() => startGame()} />
    </Wrap>
}


const generateOtherPlayersStr = (players: Player[]) => {
    const names = _.map(players, "playerName");

    if (players.length === 1) {
        return `${players[0].playerName} is`;
    } else if (players.length > 1) {
        return `${_.initial(names).join(", ")} and ${_.last(names)} are`;
    }
}


export const LandingPage = ({ playerName, setPlayerName, gameId, createGame, startGame, otherPlayers }: Props) => {
    const [userName, setUserName] = useState("");
    const [showShareScreen, setShowShareScreen] = useState(false);
    const newGame = otherPlayers.length === 0;

    if (newGame) {
        if (showShareScreen) {
            return <Wrap>
                {gameId && <ShareDetails gameId={gameId} startGame={() => playerName && startGame(playerName)} newGame={newGame} />}
            </Wrap>
        } else {
            return <Wrap>
                <Logo />
                <h1 className="text-white text-center text-5xl font-extrabold">VIDEO CALL BINGO</h1>
                <p className="text-white text-2xl mt-8 mb-8 text-center">Make your boring video calls more fun with a competitive game of bingo</p>
                <Input placeholder="Enter your name" icon={PlayerIcon} value={playerName ?? ""} onChange={setPlayerName} onEnter={() => playerName && createGame(playerName) && setShowShareScreen(true)} />
                <Button text="Start Game" disabled={!!!playerName} onClick={() => playerName && createGame(playerName) && setShowShareScreen(true)} />
            </Wrap>
        }
    } else if (!playerName) {
        return <Wrap>
            <Logo />
            <h1 className="text-white text-center text-5xl font-extrabold">VIDEO CALL BINGO</h1>
            <p className="text-white text-2xl mt-8 mb-4 text-center">Make your boring video calls more fun with a competitive game of bingo</p>
            {!newGame && <p className="text-coteam-light-yellow text-center text-xl mb-4">{generateOtherPlayersStr(otherPlayers)} already playing</p>}
            <Input placeholder="Enter your name" icon={PlayerIcon} value={userName} onChange={setUserName} onEnter={() => startGame(userName)} />
            <Button text="Join Game" disabled={!!!userName} onClick={() => {
                startGame(userName);
            }} />
        </Wrap>
    } else {
        return <></>
    }
}
