import { useState, useCallback } from 'react';

export function useQueryString(key: string) {
    const [paramValue, setParamValue] = useState(
        new URLSearchParams(window.location.search).get(key)
    );

    const onSetValue = useCallback(
        val => {
            setParamValue(val);
            updateQueryStringWithoutReload(val ? `${key}=${val}` : '');
        },
        [key, setParamValue]
    );

    function updateQueryStringWithoutReload(queryString: string) {
        const { protocol, host, pathname } = window.location;
        const newUrl = `${protocol}//${host}${pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }

    return [paramValue, onSetValue] as const;
}
