import React from 'react';

interface Props {
    text: string;
    disabled: boolean;
    onClick: () => void;
}

export const Button = ({text, disabled, onClick}: Props) => {
    return <button 
        disabled={disabled}
        onClick={() => onClick()}
        className="disabled:opacity-25 bg-white text-coteam-dark-blue rounded-full pt-3 pb-3 pl-10 pr-10 uppercase font-extrabold mt-5 mb-5 outline-none">
            
        {text}
    </button>
}
