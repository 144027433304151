import React from 'react';

interface InputProps {
    placeholder: string;
    icon?: string;
    value: string;
    onChange: (v: string) => void;
    onEnter: () => void;
}

const Icon = ({ icon }: { icon: string }) => {
    return <img src={icon} alt="" className="absolute left-0 mt-4 ml-4 w-6" />
}

export const Input = ({ placeholder, icon, value, onChange, onEnter }: InputProps) => {
    return <div className="relative">
        {icon && <Icon icon={icon} />}
        <input
            className="bg-coteam-light-blue border-white border-2 rounded-full pl-12 pt-4 pb-4 text-white placeholder-white outline-none"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' ? onEnter() : null} />
    </div>
}
