import React from 'react';
import './index.css';
import {GameContainer} from "./GameContainer";

function App() {
  return (
    <div className="App font-barlow">
      <GameContainer />
    </div>
  );
}

export default App;
