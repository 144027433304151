import React from 'react';
import { Board } from './types';
import { Wrap } from "./Layout";
import { PlayingBoard } from "./Board";
import { Button } from "./Button";
import { Logo } from "./Logo";

interface Props {
    winnerName: string;
    winningBoard: Board;
    playerName: string;
    onNewGame: (name: string) => void;
}

export const GameOver = ({ winnerName, winningBoard, playerName, onNewGame }: Props) =>
    <Wrap winCondition>
        <div className="w-48">
            <Logo />
        </div>
        <h1 className="text-white text-4xl font-bold">Bingo!</h1>
        <h1 className="text-3xl text-white font-medium">{winnerName} wins</h1>
        <h3 className="text-xl text-white">Here's the winning scorecard</h3>
        <PlayingBoard board={winningBoard} onSquareSelected={() => null} />
        <p className="text-white text-2xl mt-4 mb-4 text-center">Teams perform better with Coteam</p>
        <a href="https://coteam.org" className="bg-white text-coteam-dark-blue rounded-full pt-3 pb-3 pl-10 pr-10 uppercase font-extrabold mt-5 mb-5 outline-none" target="_blank" rel="noopener noreferrer"> Find out more</a>
        <span className="text-white font-bold text-center underline cursor-pointer" onClick={() => onNewGame(playerName)}>Start a new game</span>
    </Wrap>
