import React, { FunctionComponent } from 'react';

interface Props {
    winCondition?: boolean;
}

export const Wrap: FunctionComponent<Props> = ({ children, winCondition }) => <div className={`${winCondition ? 'bg-coteam-orange' : 'bg-coteam-light-blue'} h-full w-full flex flex-row justify-center items-center`}>
    <div className="flex flex-col items-center max-w-lg">
        {children}
    </div>
</div>
