import * as firebase from "firebase";

import { firestore } from "firebase";
import { QuerySnapshot } from "@firebase/firestore-types";
import { Board } from "./types";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBwx04STG8Vw5xs9rO3GYr_kWedys07xfA",
    authDomain: "wfh-bingo.firebaseapp.com",
    projectId: "wfh-bingo",
    appId: "1:51433570255:web:a43e186b4bc609e0c15cba",
    measurementId: "G-HR5Q3FRZRJ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();

export interface GameRoom {
    created: string;
    victor: {
        name: string;
        winningBoard: string[];
    },
    createdBy: {
        playerName: string;
        userId: string;
    }
}

export const authenticateAnonymously = () => {
    return firebase.auth().signInAnonymously();
};

export const addRoom = (playerName: string, userId: string) => {
    return db.collection('rooms').add({
        created: firestore.FieldValue.serverTimestamp(),
        victor: null,
        createdBy: { playerName, userId }
    })
}

export const getRoom = (roomId: string) => {
    return db.collection('rooms').doc(roomId).get();
}

export const subscribeToPlayers = (roomId: string, observer: (snapshot: QuerySnapshot) => void) => {
    return db.collection('rooms')
        .doc(roomId)
        .collection('players')
        .onSnapshot(observer);
}

export const playerJoin = (gameId: string, playerId: string, playerName: string) =>
    db.collection('rooms')
        .doc(gameId)
        .collection('players')
        .doc(playerId)
        .set({ playerName, boardState: [] })

export const addMove = (roomId: string, playerId: string, square: number) => {
    return db.collection('rooms')
        .doc(roomId)
        .collection('players')
        .doc(playerId)
        .update({
            boardState: firestore.FieldValue.arrayUnion(square)
        });
}

export const recordWin = (roomId: string, playerId: string, board: Board) => {
    return db.collection('rooms')
        .doc(roomId)
        .collection('players')
        .doc(playerId)
        .update({ 'winningBoard': board })
}
