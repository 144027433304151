import React from 'react';
import { Player } from "./types";
import _ from "lodash";
import classnames from "classnames";

interface Props {
    players: Player[]
}

const BoardDisplay = ({ positions }: { positions: number[] }) => {
    return <div className="flex flex-row flex-wrap w-16">
        {_.range(16).map(i =>
            <div key={i} className={classnames({
                'w-2': true,
                'h-2': true,
                'm-1': true,
                'rounded-sm': true,
                'bg-coteam-light-yellow': !positions.includes(i),
                'bg-coteam-dark-pink': positions.includes(i)
            })}></div>)}
    </div>
}

const PlayerGame = ({ player }: { player: Player }) => {
    return <div className="flex flex-col items-center w-1/4">
        <h5 className="text-lg text-white text-center mb-1">{player.playerName}</h5>
        <div>
            <BoardDisplay positions={player.boardState} />
        </div>
    </div>
}

export const PlayerGames = ({ players }: Props) => {
    return <div className="flex flex-row justify-center mt-6 mb-6">
        {players.map((p, i) => <PlayerGame player={p} key={i} />)}
    </div>
}
