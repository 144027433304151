import React from 'react';
import { Board } from "./types";


interface SquareProps {
    idx: number;
    content: string;
    selected: boolean;
    onClick: (n: number) => void;
}

export const SquareComponent = ({ idx, content, selected, onClick }: SquareProps) =>
    <div className={`text-white text-xs sm:text-sm p-1 break-words text-center flex flex-row items-center justify-center box-border cursor-pointer ${selected && 'bg-coteam-light-pink selected'} square`} onClick={() => onClick(idx)}>
        <h4>{content}</h4>
    </div>


interface PlayingBoardProps {
    board: Board;
    onSquareSelected: (idx: number) => void;
}

export const PlayingBoard = ({ board, onSquareSelected }: PlayingBoardProps) =>
    <div className="flex w-full justify-center">
        <div className="flex flex-row flex-wrap mt-10 board">
            {board.map(((s, i) =>
                <SquareComponent key={i} idx={i} content={s.content} selected={s.selected} onClick={onSquareSelected} />
            ))}
        </div>
    </div>
