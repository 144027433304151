import React, { useState, useEffect } from 'react';
import BingoBoard from "./BingoBoard";
import _ from "lodash";
import { LandingPage } from "./LandingPage";
import { addRoom, authenticateAnonymously, getRoom, subscribeToPlayers, addMove, playerJoin, recordWin } from './firebase';
import data from "./data.json";
import { useQueryString } from './hooks';
import { Player, Board, Square } from "./types";
import { QuerySnapshot, QueryDocumentSnapshot, DocumentData } from "@firebase/firestore-types";
import { GameOver } from "./GameOver";

export enum GameCondition {
    INITIAL,
    IN_PROGRESS,
    COMPLETE
}

interface GameState {
    board: Board;
    userId?: string;
    playerName?: string;
    gameId?: string;
    duration: number;
    condition: GameCondition;
    players: Player[],
    winningBoard?: {
        board: Board;
        playerName: string;
    }
}

function genVictoryRoutes(): number[][] {
    const rows = _.chain(_.range(16)).chunk(4).value();
    const columns = _.range(4).map(i => {
        return rows.map(r => r[i]);
    });
    const diagonals = [[0, 5, 10, 15], [3, 6, 9, 12]];

    return [...rows, ...columns, ...diagonals];
}

const victoryRoutes = genVictoryRoutes();

const evalGameCondition = (board: Board): GameCondition => {
    for (let route of victoryRoutes) {
        const victory = _.chain(route).map(i => board[i]).every('selected').value();

        if (victory) return GameCondition.COMPLETE;
    }

    return GameCondition.IN_PROGRESS;
}

const updateState = (idx: number, state: GameState): GameState => {
    const square = state.board[idx];
    square && (state.board[idx] = { ...square, selected: true });
    state.gameId && state.userId && addMove(state.gameId, state.userId, idx);

    if (evalGameCondition(state.board) === GameCondition.COMPLETE && state.gameId && state.userId) {
        recordWin(state.gameId, state.userId, state.board)
    }

    return state;
}

const initBoard = (): Board =>
    _.chain(data.cards)
        .sampleSize(16)
        .map(c => ({ content: c, selected: false } as Square))
        .value();

const INITIAL_STATE = {
    board: initBoard(),
    duration: 0,
    condition: GameCondition.INITIAL,
    players: []
}

export function GameContainer() {
    const [gameState, setGameState] = useState<GameState>(INITIAL_STATE);
    const [gameId, setGameId] = useQueryString('gameId');

    const createGame = (playerName: string) => {
        authenticateAnonymously().then(creds => {
            if (creds.user) {
                const userId = creds.user.uid;

                addRoom(playerName, creds.user.uid).then((docRef) => {
                    setGameState(state => (
                        {
                            ...state,
                            gameId: docRef.id,
                            userId: userId,
                            condition: GameCondition.INITIAL,
                            board: initBoard()
                        }
                    ));
                    setGameId(docRef.id);
                    docRef.collection('players')
                        .doc(creds.user?.uid)
                        .set({
                            playerName: gameState.playerName,
                            boardState: []
                        });
                })
            }
        })
    }

    const updateRemoteMove = (gameId: string, playerId: string, squareNum: number) => {
        addMove(gameId, playerId, squareNum);
    }

    const calcGameCondition = (winner?: DocumentData, currentPlayer?: DocumentData) => {
        if (winner) {
            return GameCondition.COMPLETE;
        } else if (currentPlayer) {
            return GameCondition.IN_PROGRESS;
        } else {
            return GameCondition.INITIAL;
        }

    }

    const playerUpdateHandler = (querySnapshot: QuerySnapshot) => {
        const updatedPlayers = querySnapshot.docs.map((snapshot: QueryDocumentSnapshot) => {
            return { id: snapshot.id, data: snapshot.data() }
        });

        setGameState(state => {
            const currentPlayer = state.userId ? updatedPlayers.filter(p => p.id === state.userId) : [];
            const winner = updatedPlayers.filter(p => p.data.winningBoard)[0]
            const players = updatedPlayers.map(p => ({ id: p.id, ...p.data })) as Player[];

            return {
                ...state,
                players,
                userId: currentPlayer[0]?.id,
                playerName: currentPlayer[0]?.data.playerName,
                winningBoard: winner ? { playerName: winner.data.playerName, board: winner.data.winningBoard } : undefined,
                condition: calcGameCondition(winner?.data, currentPlayer[0]?.data)
            }
        })
    }

    useEffect(() => {
        if (gameId) {
            authenticateAnonymously().then(creds => {
                setGameState(state => ({ ...state, gameId: gameId, userId: creds.user?.uid }))
                return subscribeToPlayers(gameId, playerUpdateHandler)
            });
        }
    }, [gameId, setGameId])

    const startGame = (playerName: string) => {
        authenticateAnonymously().then(creds => {
            const { gameId } = gameState;
            if (creds.user && gameId) {
                const uid = creds.user.uid;

                playerJoin(gameId, uid, playerName)
                    .then(() => setGameState(state => ({ ...state, playerName, userId: uid, condition: GameCondition.IN_PROGRESS })))
            }
        })
    }


    return <div className="w-screen h-screen">
        {gameState.condition === GameCondition.INITIAL && <LandingPage
            playerName={gameState.playerName}
            gameId={gameState.gameId}
            setPlayerName={(playerName) => setGameState(state => ({ ...state, playerName }))}
            createGame={createGame}
            startGame={startGame}
            otherPlayers={gameState.players.filter(p => p.id !== gameState.userId)}
        />}
        {gameState.condition === GameCondition.IN_PROGRESS &&
            <BingoBoard board={gameState.board} gameId={gameState.gameId} players={gameState.players} onSquareSelected={(idx) => setGameState(updateState(idx, gameState))} />}
        {gameState.condition === GameCondition.COMPLETE && gameState.winningBoard && gameState.playerName &&
            <GameOver winnerName={gameState.winningBoard.playerName} winningBoard={gameState.winningBoard.board} playerName={gameState.playerName} onNewGame={createGame} />}
    </div>

}
