import React, { useEffect, useState } from 'react';
import { Logo } from "./Logo";
import { Player, Board } from "./types";
import { PlayerGames } from './PlayerGames';
import { ShareOptions } from './ShareOptions';
import { PlayingBoard } from './Board';
import { Wrap } from "./Layout";
import PacManLoader from "react-spinners/PacmanLoader";


interface Props {
    board: Board;
    gameId?: string;
    onSquareSelected: (idx: number) => void;
    players: Player[];
}

export default function BingoBoard({ board, gameId, onSquareSelected, players }: Props) {
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setShowLoading(false), 2500);
    }, [gameId])

    if (showLoading) {
        return <Wrap>
            <Logo />
            <h1 className="text-white text-center text-5xl font-extrabold mb-5">VIDEO CALL BINGO</h1>
            <h3 className="text-white text-4xl text-center mb-5">Generating a unique board, just for you</h3>
            <PacManLoader color="#fff" />
        </Wrap>
    }

    if (players.length === 1) {
        return <Wrap>
            <Logo />
            <h1 className="text-white text-center text-5xl font-extrabold mb-5">VIDEO CALL BINGO</h1>
            <h2 className="text-white text-4xl text-center mb-5">Waiting for other players to join</h2>
            <h3 className="text-white text-3xl text-center mb-5">Invite your colleagues using the links below</h3>
            <ShareOptions gameId={gameId} />
        </Wrap>
    }

    return <div className="h-full w-full flex flex-col bg-coteam-light-blue items-center">
        <div className="max-w-lg">
            <div className="w-48">
                <Logo />
            </div>
            <p className="text-white text-center pl-5 pr-5">Select the square when it happens or someone says it in the video call. The first player to get a row of 4 wins!</p>
            <PlayingBoard board={board} onSquareSelected={onSquareSelected} />
            {players.length > 1 && <PlayerGames players={players} />}
            <ShareOptions gameId={gameId} />
        </div>
    </div>
}
