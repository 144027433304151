import React, { useState, useEffect } from 'react';
import whatsapp from "./whatsapp.svg";
import mailIcon from "./mail_round.svg";
import copyIcon from "./copy_icon.svg";

export const ShareOptions = ({ gameId }: { gameId?: string }) => {
    const { protocol, host } = window.location;
    const url = `${protocol}//${host}/?gameId=${gameId}`;
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setCopied(false), 5000);

        return () => clearTimeout(timeout);
    }, [copied])

    return gameId ? <div className="flex flex-col items-center mt-4 mb-4">
        {copied && <h6 className="text-white font-bold text-2xl">Game location copied to clipboard</h6>}

        <span className="text-white text-center font-bold">Invite your friends</span>

        <div className="flex justify-center w-full pl-12 pr-12 mt-4">
            <button className="w-10" onClick={() => navigator.clipboard.writeText(url).then(() => setCopied(true))}>
                <img src={copyIcon} alt="Clipboard icon for copying game url to the clipboard"/>
            </button>
            <a className="w-10 ml-4" href={`https://wa.me/?text=${encodeURI(`Join me playing Video Call Bingo at ${url}`)}`} target="_blank" rel="noopener noreferrer">
                <img src={whatsapp} alt="whatsapp logo" />
            </a>
            <a className="w-10 ml-4" href={`mailto:${encodeURI(`?subject=Join me playing WFH Bingo&body=Hi!\n\nCome play Video Call Bingo with me at ${url}`)}`} target="_blank" rel="noopener noreferrer">
                <img className="text-white stroke-current fill-current" src={mailIcon} alt="Mail icon" />
            </a>
        </div>
    </div> : <></>
}
